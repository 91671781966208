body {
  --speed: 30000ms
}

.infinite-scroll-root-container {}

.infinite-scroll-root-container>:nth-child(n):hover>.infinite-scroll-container {
  animation-play-state: paused;
}

.infinite-scroll-root-container>:nth-child(2n)>.infinite-scroll-container {
  animation-direction: reverse;
}

.infinite-scroll-root-container>:nth-child(2n-1)>.infinite-scroll-container {
  /* animation: swipe var(--speed) linear infinite backwards; */
  animation: swipe var(--speed) linear infinite backwards;
}

.infinite-scroll-container {
  display: flex;
  animation: swipe var(--speed) linear infinite backwards;
}

.infinite-scroll-container>:nth-child(n) {
  margin-left: 24px;
}

@keyframes swipe {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translate(-100%);
  }
}

.zoom-large {
  zoom: 1.2;
}


.barrage {
  animation: opacity-gradient 3000ms ease-in;
}

@keyframes opacity-gradient {
  0% {
    opacity: 0;
  }

  1% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}