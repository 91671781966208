@import url('./css/font.css');
@import url('./css/global.css');

@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  min-width: 1440px;
}

#root {
  height: 100vh;
  overflow: auto;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #ededed !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  background-color: transparent;
  background-image: none;
  transition: background-color 50000s ease-in-out 0s;
}

* {
  box-sizing: border-box;
}

/* * input {
  background: transparent;
}

* textarea {
  background: transparent;
} */
/* 
@layer base {
  html {
    color-scheme: light !important;
  }
} */